<template>
  <div>
    <ValidationObserver v-slot="{ valid }">
      <div>
        <ValidationProvider
          rules="required|email|min:3"
          name="correo"
          v-slot="{ errors }"
        >
          <vs-input
            data-vv-validate-on="blur"
            name="email"
            icon-no-border
            icon="icon icon-user"
            :danger-text="errors[0]"
            :danger="!!errors.length"
            icon-pack="feather"
            label-placeholder="Correo"
            v-model="email"
            class="w-full"
            data-test="emailLoginInput"
          />
        </ValidationProvider>
      </div>

      <ValidationProvider
        rules="required|min:6|max:10"
        name="contraseña"
        v-slot="{ errors }"
      >
        <vs-input
          data-vv-validate-on="blur"
          type="password"
          name="password"
          icon-no-border
          icon="icon icon-lock"
          :danger-text="errors[0]"
          :danger="!!errors.length"
          icon-pack="feather"
          label-placeholder="Contraseña"
          v-model="password"
          class="w-full mt-8"
          data-test="passwordLoginInput"
        />
      </ValidationProvider>

      <!--  v-validate="'required|min:6|max:10'" -->
      <!-- <span class="text-danger text-sm">{{ errors.first('password') }}</span> -->

      <div class="flex flex-wrap justify-between my-5">
        <vs-checkbox v-model="checkbox_remember_me" class="mb-3 text-slate-gray"
          >Recordar datos</vs-checkbox
        >
        <router-link to="/pages/forgot-password"
          >Olvidé mi contraseña</router-link
        >
      </div>

      <div class="flex flex-wrap justify-between mb-3">
        <vs-button type="border" @click="registerUser">Registrarse</vs-button>
        <vs-button
          :disabled="!validateForm(valid)"
          data-test="submitLoginBtn"
          @click="loginJWT"
          >Iniciar sesión</vs-button
        >
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: '',
      password: '',
      checkbox_remember_me: false,
    };
  },
  computed: {
    validateForm() {
      return (valid) => {
        return valid && this.email != '' && this.password != '';
      };
    },
  },
  methods: {
    checkLogin() {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {
        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
          title: 'Login Attempt',
          text: 'You are already logged in!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
        });

        return false;
      }
      return true;
    },
    loginJWT() {
      if (!this.checkLogin()) return;

      // Loading
      this.$vs.loading();

      const payload = {
        checkbox_remember_me: this.checkbox_remember_me,
        userDetails: {
          email: this.email,
          password: this.password,
        },
      };

      this.$store
        .dispatch('auth/loginJWT', payload)
        .then(() => {
          this.$router.push('/');
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: 'Error',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          });
        });
    },
    registerUser() {
      if (!this.checkLogin()) return;
      this.$router.push('/registrarse').catch(() => {});
    },
  },
};
</script>
